.slick-slider {
    max-width: calc(440px * 0.9);
}
.slider--banner .slick-dots {
    top: -2.33rem;
    right: 6.72rem;
    bottom: unset;
    width: fit-content;
}
.slider--banner .slick-dots li {
    display: none;
    width: fit-content;
    height: unset;
    margin: 0;
}
.slider--banner .slick-dots .slick-active {
    display: block;
}
.slider--banner .slick-dots .slick-active strong {
    color: #ff3062;
}
.slider--banner .slick-slide {
    padding: 0 0.5rem;
}
.slider--banner .slick-slide img {
    opacity: 0.3;
}
.slider--banner .slick-active img {
    opacity: 1;
    -wetbki-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
.slider--search {
    max-width: 100%;
}
.slick-prev,
.slick-next {
    z-index: 9;
}
.slick-prev:before,
.slick-next:before {
    display: none;
}
.slick-prev {
    left: 10px;
}
.slick-next {
    right: 10px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background: rgba(0, 0, 0, 0.1);
}
